Drupal.behaviors.productHeaderV1 = {
  attach: function (context) {
    var $testimonialLink = $('.js-product-name', context);

    if ($testimonialLink.length < 1) {
      return null;
    }

    $testimonialLink.on('click', function (e) {
      e.preventDefault();
    });

    var html = site.template.get({
      name: 'product_testimonial_v1',
      data: $testimonialLink.data()
    });

    $testimonialLink.tooltipster({
      content: html,
      trigger: 'hover',
      contentAsHTML: true,
      maxWidth: 225,
      theme: 'spp-tooltip',
      position: 'bottom'
    });
  }
};
